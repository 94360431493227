import PartySocket from "partysocket";
import Peer from "./audio";
import * as map from "./map";
import type { UserInput } from "./types/client-types";
import type { ClientMessage, RoomState, ServerMessage, User } from "./types/messages";

declare const PARTYKIT_HOST: string;

class Client {
	_audio: Peer;
	_conn: PartySocket;
	_media: MediaStream;
	_clientRoom: RoomState;
	_intervalId: number;

	_routeDrawn: boolean;

	constructor(room: string, input: UserInput, audio: Peer, media: MediaStream) {
		this._audio = audio
		this._media = media
		this._clientRoom = { users: [] }
		this._routeDrawn = false
		this._intervalId = 0
		this._conn = new PartySocket({
			host: PARTYKIT_HOST,
			room,
			query: {
				user: input.username,
				leader: input.leader ? "t" : "f",
				sessionId: audio.sessionId,
				trackId: audio.trackId,
			},
		})

		this._conn.addEventListener("open", this.onOpen.bind(this, input))
		this._conn.addEventListener("message", this.onMessage.bind(this))
		this._conn.addEventListener("close", this.onClose.bind(this))
	}

	onClose() {
		if (this._intervalId) clearInterval(this._intervalId)
	}

	onOpen(input: UserInput) {
		if (input.leader) {
			// share leaders gpx file others
			let clientMessage: ClientMessage = {
				type: "userUpdate",
				//@ts-ignore
				user: {
					gpxRoute: input.gpxRoute,
				},
			}
			this._conn.send(JSON.stringify(clientMessage))

			// share leader location every few seconds
			this._intervalId = setInterval(() => {
				let user:User|undefined = map.getUserLocation()
				if (!user) return

				let clientMessage: ClientMessage = {
					type: "userUpdate",
					//@ts-ignore
					user: {
						lat: user.lat,
						lng: user.lng
					}
				}
				this._conn.send(JSON.stringify(clientMessage))
			}, 2500)
		}
	}

	onMessage(event: MessageEvent<string>) {
		const data: ServerMessage = JSON.parse(event.data);

		switch (data.type) {
			case "roomState":
				this.onRoomState(data.state);
				break;

			default:
				break;
		}
	}

	getLeaderAndNonLeaders(data: { leader: any; name: string }[]) {
		let leader = "";
		let nonLeaders: any[] = [];

		data.forEach((person: { leader: any; name: string }) => {
			if (person.leader) {
				leader = person.name;
			} else {
				nonLeaders.push(person.name);
			}
		});

		return {
			leader: leader,
			nonLeaders: nonLeaders,
		};
	}

	addDriverList(serverRoom: RoomState) {
		const leader$ = document.getElementById("leader");
		const drivers$ = document.getElementById("driverList");

		const drivers = this.getLeaderAndNonLeaders(serverRoom.users);
		if (leader$ != null) {
			leader$.innerHTML = drivers.leader;
			leader$.style.color = "blue";
		}
		if (drivers$ != null) {
			drivers$.innerHTML = drivers.nonLeaders.join(", ")
		}
	}

	updateLeaderMarker(serverRoom: RoomState) {
		const leader = serverRoom.users.find(user => user.leader);

		// don't update myself
		if (this._conn.id === leader?.id) return

		if (leader?.lng && leader?.lat) {
			map.updateLeaderMarker(leader.lng, leader.lat)
		}
	}

	async onRoomState(serverRoom: RoomState) {
		const clientUserIds = new Set(
			this._clientRoom.users.map((user) => user.id)
		);
		const serverUserIds = new Set(serverRoom.users.map((user) => user.id));

		// might need to move this to the right spot
		this.addDriverList(serverRoom);

		// update leader location on map
		this.updateLeaderMarker(serverRoom)

		// Check for users who joined
		for (const user of serverRoom.users) {
			if (user.id === this._conn.id) continue; // Skip current user

			if (!clientUserIds.has(user.id)) {
				console.log(`user joined: ${user.name} (ID: ${user.id})`);

				try {
					const { sessionId, trackId } = user.tracks;

					console.log(
						"pulling user",
						user.name,
						"track",
						`${sessionId}/${trackId}`
					);
					await this._audio.pullTrack(
						[
							{
								location: "remote",
								sessionId: sessionId,
								trackName: trackId,
							},
						],
						this._media
					);
				} catch (err: any) {
					// TODO: add some retries
					console.error("failed pull track", err);
				}
			}

			if (user.leader && 
				user.gpxRoute && 
				!this._routeDrawn &&
				map.isMapLoaded()
			) {
				map.showRouteOnMap(user.gpxRoute);
				this._routeDrawn = true;
			}
		}

		// Check for users who left
		for (const user of this._clientRoom.users) {
			if (!serverUserIds.has(user.id)) {
				console.log(`user left: ${user.name} (ID: ${user.id})`);

				try {
					const { sessionId, trackId } = user.tracks;
					await this._audio.removeTrack(
						[
							{
								sessionId: sessionId,
								trackName: trackId,
								mid: "1",
							},
						],
						this._media
					);
				} catch (err: any) {
					console.error("failed remove track", err);
				}
			}
		}

		this._clientRoom = serverRoom;
	}
}

export default Client;
