//@ts-nocheck
import type { UserInput } from "./types/client-types";
import mapboxgl from "mapbox-gl";
import tj from "@mapbox/togeojson";
import * as turf from "@turf/turf";

mapboxgl.accessToken =
	"pk.eyJ1Ijoiem1pcnphIiwiYSI6ImNseGMwanozdTBjZTkyaXB1ZHRkZ3kwM3QifQ.veBdJRp88dJvU-lqqobBxQ";

let map;
let routeCoords = [];
let directionsData;
let currentMarker;
let leaderMarker;
let stepIndex = -1; // Initialize to -1 to ensure the first instruction is given
let isMapInit = false

// Start the app when the user clicks "Drive!"
export async function init(): Promise<UserInput> {
	const userName = document.getElementById("userName").value.trim();
	if (!userName) {
		alert("Please enter your name.");
		return;
	}
	const isDriverLeader = document.getElementById("driveLeader").checked;

	let gpxRouteData;
	if (isDriverLeader) {
		const gpxFile = document.getElementById("gpxUpload").files[0];
		if (!gpxFile) {
			alert("Please upload a GPX file.");
			return;
		}
		try {
			gpxRouteData = await parseGPX(gpxFile, userName);
		} catch (e) {
			console.error(`error parsing gpx file`, e);
			alert("Invalid GPX file. Please upload a valid route.");
		}
	}

	// Get user's current location
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const userLocation = [
					position.coords.longitude,
					position.coords.latitude,
				];

				// Initialize the map with user's location
				initializeMap(userLocation, userName, gpxRouteData);

				// Hide the user form and show the map and controls
				document.getElementById("userForm").style.display = "none";
				document.getElementById("map").style.display = "block";
				document.getElementById("control_wrapper").style.display = "block";
				document.getElementById("instructions").style.display = "block";
			},
			(error) => {
				console.error("Error getting location", error);
				alert(
					"Unable to access your location. Please allow location access and try again."
				);
			}
		);
	} else {
		alert("Geolocation is not supported by this browser.");
	}

	return { username: userName, leader: isDriverLeader, gpxRoute: gpxRouteData };
}

export function showRouteOnMap(geojson: any): bool {
	if (
		geojson.features.length > 0 &&
		geojson.features[0].geometry.type === "LineString"
	) {
		// Extract coordinates
		routeCoords = geojson.features[0].geometry.coordinates;
	}

	// Fetch matched route from Map Matching API
	fetchMatchedRoute(routeCoords).then((matchedRouteCoords) => {
		if (!matchedRouteCoords) {
			return;
		}

		let { lng, lat } = currentMarker.getLngLat();
		let userLocation = [lng, lat];

		// Extract waypoints for Directions API (max 25)
		const waypoints = extractWaypoints(matchedRouteCoords, userLocation);

		// Fetch directions with voice instructions from Directions API
		fetchDirections(waypoints).then(() => {
			if (!directionsData) {
				return;
			}
			console.log(directionsData);
			// Plot the route
			plotRoute();
			// Add origin and destination markers
			addMarkers();

			// Fit the map to the route bounds
			// fitMapToRoute();
			// Add step markers
			// addStepMarkers();
			// Display the first instruction
			if (directionsData && getAllSteps().length > 0) {
				updateInstructions(0); // Start at the beginning of the route
			} else {
				displayInstruction("No directions available.");
			}
		});
	});
}

export function getUserLocation(): User|undefined {
	return currentMarker?.getLngLat()
}

export function updateLeaderMarker(lng: number, lat: number) {
	leaderMarker = leaderMarker || new mapboxgl.Marker({
		color: "red",
	})
		.setLngLat([lng, lat])
		.addTo(map)
	
	leaderMarker.setLngLat([lng, lat])
}

export function isMapLoaded() {
	return isMapInit
}

// Parse the GPX file and extract route coordinates
function parseGPX(file, userName): Promise<any> {
	const reader = new FileReader();

	return new Promise((res, rej) => {
		reader.onload = function () {
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(reader.result, "application/xml");
			const geojson = tj.gpx(xmlDoc);

			if (
				geojson.features.length > 0 &&
				geojson.features[0].geometry.type === "LineString"
			) {
				res(geojson);
			} else {
				alert("Invalid GPX file. Please upload a valid route.");
				rej("invalid gpx file");
			}
		};
		reader.readAsText(file);
	});
}

// Initialize the map
function initializeMap(userLocation, userName, gpxRouteData) {
	console.log("initializeMap: ", initializeMap);
	map = new mapboxgl.Map({
		container: "map",
		style: "mapbox://styles/mapbox/streets-v11", // Changed to streets style without traffic
		center: userLocation,
		zoom: 14,
	});

	map.on("load", () => {
		// Add draggable current location marker
		addCurrentLocationMarker(userLocation);

		if (gpxRouteData) {
			showRouteOnMap(gpxRouteData);
		}

		isMapInit = true
	});
}

// Fetch matched route from Map Matching API
async function fetchMatchedRoute(coords) {
	// Simplify the line to reduce the number of coordinates
	const line = turf.lineString(coords);
	const options = { tolerance: 0.0001, highQuality: false };
	const simplified = turf.simplify(line, options);

	// Limit to a maximum of 100 coordinates (Map Matching API limit)
	const maxCoords = 100;
	const totalCoords = simplified.geometry.coordinates.length;
	const step = Math.ceil(totalCoords / maxCoords);
	let reducedCoords = simplified.geometry.coordinates.filter(
		(_, index) => index % step === 0
	);

	const coordinates = reducedCoords.map((coord) => coord.join(",")).join(";");

	const url = `https://api.mapbox.com/matching/v5/mapbox/driving/${coordinates}?geometries=geojson&steps=true&access_token=${mapboxgl.accessToken}`;

	const response = await fetch(url);
	const data = await response.json();

	console.log("Matched Route Data:", data);

	if (data.code === "Ok") {
		const matchedCoords = data.matchings[0].geometry.coordinates;
		return matchedCoords;
	} else {
		alert(`Error fetching matched route: ${data.message}`);
		console.error(data);
		return null;
	}
}

// Extract waypoints for Directions API (max 25 including start and end)
function extractWaypoints(matchedCoords, userLocation) {
	const maxWaypoints = 25;

	// Start with user's location
	let waypoints = [userLocation];

	// Calculate how many points we can include from the matched route
	const remainingWaypoints = maxWaypoints - 2; // Reserve for start and end
	const totalCoords = matchedCoords.length;
	const step = Math.ceil(totalCoords / remainingWaypoints);

	// Select evenly spaced waypoints along the route
	const selectedCoords = matchedCoords.filter((_, index) => index % step === 0);

	// Remove the first coordinate if it's the same as user's location
	if (
		selectedCoords.length > 0 &&
		selectedCoords[0][0] === userLocation[0] &&
		selectedCoords[0][1] === userLocation[1]
	) {
		selectedCoords.shift();
	}

	// Add selected waypoints
	waypoints = waypoints.concat(selectedCoords);

	// Ensure we have at most maxWaypoints
	waypoints = waypoints.slice(0, maxWaypoints - 1);

	// Add the final destination
	waypoints.push(matchedCoords[matchedCoords.length - 1]);

	return waypoints;
}

// Fetch directions from Mapbox Directions API
async function fetchDirections(waypoints) {
	const coordinates = waypoints.map((coord) => coord.join(",")).join(";");
	const encodedCoordinates = encodeURIComponent(coordinates);

	// Directions API URL without the 'voice' parameter and with encoded coordinates
	const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${encodedCoordinates}?geometries=geojson&steps=true&overview=full&voice_instructions=true&voice_units=imperial&language=en&access_token=${mapboxgl.accessToken}`;

	const response = await fetch(url);
	const data = await response.json();

	console.log("Directions Data:", data);

	if (data.code === "Ok") {
		directionsData = data.routes[0];
		// Use the route geometry
		routeCoords = directionsData.geometry.coordinates;
	} else {
		alert(`Error fetching directions: ${data.message}`);
		console.error(data);
	}
}

// Plot the route on the map
function plotRoute() {
	if (map.getSource("route")) {
		map.getSource("route").setData({
			type: "Feature",
			properties: {},
			geometry: {
				type: "LineString",
				coordinates: routeCoords,
			},
		});
	} else {
		map.addSource("route", {
			type: "geojson",
			data: {
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: routeCoords,
				},
			},
		});

		map.addLayer({
			id: "route",
			type: "line",
			source: "route",
			layout: {
				"line-join": "round",
				"line-cap": "round",
			},
			paint: {
				"line-color": "#1DB954",
				"line-width": 6,
			},
		});
	}
}

// Add origin and destination markers
function addMarkers() {
	// Origin Marker
	new mapboxgl.Marker().setLngLat(routeCoords[0]).addTo(map);

	// Destination Marker
	new mapboxgl.Marker()
		.setLngLat(routeCoords[routeCoords.length - 1])
		.addTo(map);
}

// Create a custom marker element
function createMarkerElement(className) {
	const el = document.createElement("div");
	el.className = className;
	return el;
}

// Add a draggable current location marker
function addCurrentLocationMarker(position) {
	currentMarker = new mapboxgl.Marker({
		draggable: true,
	})
		.setLngLat(position)
		.addTo(map);

	currentMarker.on("dragend", onMarkerDragEnd);
}

// Handle marker drag end event
function onMarkerDragEnd() {
	const lngLat = currentMarker.getLngLat();
	const point = turf.point([lngLat.lng, lngLat.lat]);
	const line = turf.lineString(routeCoords);

	// Calculate the snapped point on the route
	const snappedPoint = turf.nearestPointOnLine(line, point, {
		units: "meters",
	});
	const distanceFromRoute = turf.pointToLineDistance(point, line, {
		units: "meters",
	});

	// Threshold to consider whether the marker is on the route (e.g., 50 meters)
	const threshold = 50;

	if (distanceFromRoute <= threshold) {
		// Marker is on or near the route
		// Move the marker to the snapped point
		currentMarker.setLngLat(snappedPoint.geometry.coordinates);

		// Update instructions based on position along the route
		updateInstructions(snappedPoint.properties.location);
	} else {
		// Marker is off the route
		displayInstruction("Proceed to the highlighted route.");
		speakInstruction("Proceed to the highlighted route.");
	}
}

// Combine steps from all legs into a single array
function getAllSteps() {
	const allSteps = [];
	directionsData.legs.forEach((leg) => {
		allSteps.push(...leg.steps);
	});
	return allSteps;
}

// Format distance in imperial units
function formatDistanceImperial(meters) {
	const feet = meters * 3.28084;
	if (feet < 1000) {
		return `${Math.round(feet)} feet`;
	} else {
		const miles = meters * 0.000621371;
		return `${miles.toFixed(1)} miles`;
	}
}

// Update instructions based on marker position along the route
function updateInstructions(locationAlongRoute) {
	const steps = getAllSteps();

	let cumulativeDistance = 0;
	let totalRouteDistance = directionsData.distance; // Total distance of the route in meters

	// Threshold to determine arrival at destination (e.g., within 50 meters)
	const arrivalThreshold = 50;

	// If locationAlongRoute is less than zero, user is before the route starts
	if (locationAlongRoute < 0) {
		displayInstruction("Proceed to the starting point of the route.");
		speakInstruction("Proceed to the starting point of the route.");
		return;
	}

	// If locationAlongRoute exceeds total route distance minus threshold, user has arrived
	if (locationAlongRoute >= totalRouteDistance - arrivalThreshold) {
		displayInstruction("You have arrived at your destination.");
		speakInstruction("You have arrived at your destination.");
		return;
	}

	// Loop through the steps to find the current instruction
	for (let i = 0; i < steps.length; i++) {
		cumulativeDistance += steps[i].distance; // steps[i].distance is in meters

		if (locationAlongRoute <= cumulativeDistance) {
			if (i !== stepIndex) {
				stepIndex = i;
				const step = steps[stepIndex];
				const instruction = step.maneuver.instruction;
				const distance = formatDistanceImperial(step.distance);
				const instructionWithDistance = `${instruction} for ${distance}`;
				displayInstruction(instructionWithDistance);

				// Play voice instruction
				playVoiceInstruction(step);
			}
			return;
		}
	}
	// If the marker has moved beyond the last step (should be handled above)
	displayInstruction("You have arrived at your destination.");
	speakInstruction("You have arrived at your destination.");
}

// Play voice instruction
function playVoiceInstruction(step) {
	if (step.voiceInstructions && step.voiceInstructions.length > 0) {
		const instruction = step.voiceInstructions[0];
		const audioUrl = instruction.audioFile;

		if (audioUrl) {
			// Play the audio file
			const audio = new Audio(audioUrl);
			audio.play().catch((error) => {
				console.error("Error playing audio:", error);
				// Fallback to text-to-speech
				speakInstruction(instruction.announcement);
			});
		} else if (instruction.announcement) {
			// Use the announcement text with speech synthesis
			speakInstruction(instruction.announcement);
		}
	} else {
		// Fallback if no voice instructions are available
		const instructionText = step.maneuver.instruction;
		speakInstruction(instructionText);
	}
}

// Speak instruction using Web Speech API
function speakInstruction(text) {
	const utterance = new SpeechSynthesisUtterance(text);
	speechSynthesis.speak(utterance);
}

// Display instruction on the screen
function displayInstruction(text) {
	document.getElementById("instructions").innerText = text;
}

// Fit the map to the route bounds
function fitMapToRoute() {
	const bounds = routeCoords.reduce(
		(bounds, coord) => bounds.extend(coord),
		new mapboxgl.LngLatBounds(routeCoords[0], routeCoords[0])
	);
	map.fitBounds(bounds, { padding: 50 });
}

// Add markers for each step in the directions data
function addStepMarkers() {
	const steps = getAllSteps();

	steps.forEach((step, index) => {
		const distance = formatDistanceImperial(step.distance);
		const marker = new mapboxgl.Marker({ color: "#FF0000" })
			.setLngLat(step.maneuver.location)
			.setPopup(
				new mapboxgl.Popup({ offset: 25 }) // Add popups
					.setText(
						`Step ${index + 1}: ${step.maneuver.instruction} for ${distance}`
					)
			)
			.addTo(map);
	});
}

// Toggle drive leader options visibility
export function toggleDriveLeaderOptions() {
	const isChecked = document.getElementById("driveLeader").checked;
	const driveLeaderOptions = document.getElementById("driveLeaderOptions");
	if (isChecked) {
		driveLeaderOptions.classList.remove("hidden");
	} else {
		driveLeaderOptions.classList.add("hidden");
	}
}
