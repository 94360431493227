import "./styles.css";

import * as map from "./map";
import Peer from "./audio";
import Client from "./client";

const leader$ = document.getElementById("driveLeader");
leader$?.addEventListener("change", map.toggleDriveLeaderOptions);

const drive$ = document.getElementById("drive");
drive$?.addEventListener("click", startApp);

const audio$ = document.getElementById("remote") as HTMLAudioElement;

const muteMe$ = document.getElementById("mute-me");
muteMe$?.addEventListener("click", toggleMute);

const mediaStream = new MediaStream();

const peer = new Peer();

async function startApp() {
	let userInput = await map.init();

	audio$.srcObject = mediaStream;

	try {
		await peer.init();
	} catch (err: any) {
		alert(`failed to establish audio - ${err}`);
		window.location.reload();
	}
	// wait a few seconds to allow audio track to be pushed
	// there's a strange bug where users receive track info via websocket
	// faster than the track info pushed to cf server
	await new Promise((r) => setTimeout(r, 2500));

	muteMe();

	new Client("room", userInput, peer, mediaStream);
}

function muteMe() {
	const audioTrack = peer.media.getAudioTracks()[0];
	if (audioTrack == null) {
		console.log("muteAll: no audio track found");
		return;
	}
	audioTrack.enabled = false;
	if (muteMe$ != null) {
		muteMe$.innerHTML = "Unmute ME";
	}
}

function unMuteMe() {
	const audioTrack = peer.media.getAudioTracks()[0];
	if (audioTrack == null) {
		console.log("muteAll: no audio track found");
		return;
	}
	audioTrack.enabled = true;
	if (muteMe$ != null) {
		muteMe$.innerHTML = "Mute ME";
	}
}

function toggleMute() {
	if (muteMe$?.innerHTML === "Mute ME") {
		muteMe();
	} else {
		unMuteMe();
	}
}
