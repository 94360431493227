import {
    type RenegotiationResponse,
    type NewSessionResponse,
    type TrackObject,
    type TracksRequest,
    type TracksResponse,
    type CloseTracksRequest,
    type TurnKeysResponse,
    type SessionDescription,
    type ErrorResponse
} from "./types/call-types"

import fetchRetry from "fetch-retry"

const iceGathertingTimeout = 1500 /* ms */

const fetch = fetchRetry(window.fetch, {
    retries: 3,
    retryDelay: attempt => Math.pow(2, attempt) * 500,
    retryOn: async function(attempt, error, response) {
        if (attempt > 3) return false

        if (!response) return false

        if ([500, 503].includes(response!.status)) {
            return true
        }

        /*
        let errorResponse = JSON.parse(await response.text())
        if (errorResponse.errorCode === "backend_error") {
            return true
        }
        */
        
        return false
    }
})

// STUN
const SFU_APP_ID = "5df565de522fa481b2dcfa5df0e79dc5"
const SFU_API_TOKEN = "8e31fc13ae8c640ceabd29c63ee5d18a52b4d5cc4a33bb6d61cd2c0541cb6ca2"

// TURN
const TURN_APP_ID = "b945e33bc457484121a4a22a03f824dc"
const TURN_API_TOKEN = "9b0ec10c762bbedb4b793644ce326314bfa4db9b2c011fb2bfbd1e28b705fd78"
const API_BASE = `https://rtc.live.cloudflare.com/v1/apps/${SFU_APP_ID}`

export default class Peer {
    media!: MediaStream
    sessionId!: string
    pc!: RTCPeerConnection
    transceivers: RTCRtpTransceiver[]

    constructor() {
        this.transceivers = []
    }

    static get headers() {
        return {
            'Authorization': `Bearer ${SFU_API_TOKEN}`
        }
    }

    get trackId(): string | undefined {
        if (this.transceivers && this.transceivers.length > 0) {
            return this.transceivers[0].sender.track?.id
        }
    }

    async init(): Promise<void> {
        this.media = await navigator.mediaDevices.getUserMedia({
            audio: true
        })

        // First, we'll establish the "local" Calls session by calling createCallsSession
        // which is defined towards the bottom of this script. This will create an
        // a Calls session, and return the session ID.
        this.sessionId = await this.createCallsSession()

        // Then we create a simple RTCPeerConnection with some standard parameters.
        this.pc = await this.createPeerConnection()

        this.pc.addEventListener('iceconnectionstatechange', this.handleIceFailure.bind(this))

        // Next we need to push our audio and video tracks. We will add them to the peer
        // connection using the addTransceiver API which allows us to specify the direction
        this.transceivers = this.media.getTracks().map((track) =>
            this.pc!.addTransceiver(track, {
                direction: "sendonly",
            }),
        )

        // Now that the peer connection has tracks we create an SDP offer.
        // And apply that offer as the local description.
        await this.pc.setLocalDescription(
            await this.pc.createOffer()
        )

        // Send the local session description to the Calls API, it will
        // respond with an answer and trackIds.
        let remoteSessionDesc = await this.pushLocalTrack()

        // We take the answer we got from the Calls API and set it as the
        // peer connection's remote description, which is an answer in this case.
        await this.pc.setRemoteDescription(
            new RTCSessionDescription(remoteSessionDesc)
        )

        // Setting up the ICE connection state handler needs to happen before
        // setting the remote description to avoid race conditions.
        const connectedState = new Promise((res, rej) => {
            // timeout after 5s
            setTimeout(rej, 5000);
            const iceConnectionStateChangeHandler = () => {
                console.log('iceconnectionstate', this.pc.iceConnectionState)
                if (this.pc.iceConnectionState === "connected") {
                    this.pc.removeEventListener(
                        "iceconnectionstatechange",
                        iceConnectionStateChangeHandler,
                    )
                    res(undefined)
                }
            }
            this.pc.addEventListener(
                "iceconnectionstatechange",
                iceConnectionStateChangeHandler,
            )
        })

        // Wait until the peer connection's iceConnectionState is "connected"
        await connectedState;
    }

    handleIceFailure() {
        if (!this.pc) return

        const { iceConnectionState } = this.pc
        if (iceConnectionState === 'closed' || iceConnectionState === 'failed') {
            alert(
                `Oh no! It appears that your connection closed unexpectedly. We've copied your session id to your clipboard, and will now reload the page to reconnect!`
            )
            window.location.reload()
        }
    }

    async createCallsSession(): Promise<string> {
        const sessionResponse = await fetch(
            `${API_BASE}/sessions/new`,
            {
                method: "POST",
                headers: Peer.headers,
            },
        ).then((res) => res.json<NewSessionResponse>())

        return sessionResponse.sessionId
    }

    async createPeerConnection(): Promise<RTCPeerConnection> {
        const {iceServers} = await fetch(
            `https://rtc.live.cloudflare.com/v1/turn/keys/${TURN_APP_ID}/credentials/generate`,
            {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${TURN_API_TOKEN}`
                },
                body: JSON.stringify({ "ttl": 86400 })
            }
        ).then(res => res.json<TurnKeysResponse>())

        return new RTCPeerConnection({
            iceServers: [iceServers],
            bundlePolicy: "max-bundle",
        });
    }

    async pushLocalTrack(): Promise<SessionDescription> {
        let tracksRequest: TracksRequest = {
            sessionDescription: {
                type: "offer",
                sdp: this.pc!.localDescription!.sdp
            },
            tracks: this.transceivers?.map(({ mid, sender }) => ({
                location: "local",
                mid: mid,
                trackName: sender.track?.id
            }))!
        }

        let {sessionDescription, errorCode} = await fetch(
            `${API_BASE}/sessions/${this.sessionId}/tracks/new`,
            {
                method: "POST",
                headers: Peer.headers,
                body: JSON.stringify(tracksRequest)
            }
        ).then(res => res.json<TracksResponse>())

        if (errorCode) {
            console.log(`[error] push track error`, errorCode)
            return Promise.reject(errorCode)
        }

        console.log('audio', this.sessionId, 'pushed local track')

        return sessionDescription
    }

    async pullTrack(tracks: TrackObject[], mediaStream: MediaStream): Promise<void> {
        const response = await fetch(
            `${API_BASE}/sessions/${this.sessionId}/tracks/new`,
            {
                method: "POST",
                headers: Peer.headers,
                body: JSON.stringify({ tracks })
            }
        )

        if (!response.ok) {
            console.error("fetch pull tracks error", response.status)
            return
        }

        const pullTracksResponse = await response.json<TracksResponse>()

        // We set up this promise before updating local and remote descriptions
        // so the "track" event listeners are already in place before they fire.
        const resolveTracks = Promise.all<MediaStreamTrack>(
            pullTracksResponse.tracks!.map(
                ({ mid }) =>
                    // This will resolve when the track for the corresponding mid is added.
                    new Promise((res, rej) => {
                        setTimeout(rej, 5000);
                        const handleTrack = ({ transceiver, track }: any) => {
                            if (transceiver.mid !== mid) return;
                            this.pc?.removeEventListener(
                                "track",
                                handleTrack,
                            );
                            res(track);
                        };
                        this.pc?.addEventListener(
                            "track",
                            handleTrack,
                        );
                    }),
            ),
        );

        // Handle renegotiation, this will always be true when pulling tracks
        if (pullTracksResponse.requiresImmediateRenegotiation) {
            // We got a session description from the remote in the response,
            // we need to set it as the remote description
            this.pc?.setRemoteDescription(
                pullTracksResponse.sessionDescription,
            );
            // Create and set the answer as local description
            await this.pc?.setLocalDescription(
                await this.pc.createAnswer(),
            );
            // Send our answer back to the Calls API
            // const renegotiateResponse = await fetch(
            let response = await fetch(
                `${API_BASE}/sessions/${this.sessionId}/renegotiate`,
                {
                    method: "PUT",
                    headers: Peer.headers,
                    body: JSON.stringify({
                        sessionDescription: {
                            sdp: this.pc?.currentLocalDescription?.sdp,
                            type: "answer",
                        },
                    }),
                },
            )
            if (!response.ok) {
                console.error("fetch renegotiate error", response.status)
                return
            }

            const { errorCode, errorDescription } = await response.json<RenegotiationResponse>()
            if (errorCode) {
                console.error("error renegotiating", errorCode, errorDescription)
            }
        }

        let tracksToAdd = await resolveTracks
        tracksToAdd.forEach(track => mediaStream.addTrack(track))

        console.log(
            'audio', 'pulled remote track', tracks.map(track => `${track.sessionId}/${track.trackName}`)
        )
    }

    async removeTrack(tracks: TrackObject[], mediaStream: MediaStream): Promise<void> {
        let request: CloseTracksRequest = {
            force: true,
            tracks: tracks
        }

        const response = await fetch(
            `${API_BASE}/sessions/${this.sessionId}/tracks/close`,
            {
                method: "PUT",
                headers: Peer.headers,
                body: JSON.stringify(request)
            }
        )

        if (!response.ok) {
            console.error("fetch remove tracks error", response.status)
            return
        }

        const trackIdsToRemove = tracks.map(track => track.trackName)
        for (let trackId of trackIdsToRemove) {
            const track = mediaStream.getAudioTracks()
                .find(track => track.id === trackId)

            if (track) {
                mediaStream.removeTrack(track)
            }
        }
    }
}